<template>
  <div class="col-12 col-lg-8 c g">
    <div class="card">
      <div class="card-header text-center">
        <h4>
          <i class="fa fa-list"></i>
          الاقسام &nbsp;
          <button class="btn btn-sm btn-success" @click="addNew()">
            <i class="fa fa-plus"></i>
            إضافة قسم جدديد
          </button>
        </h4>
      </div>
      <div class="card-body">
        <div class="col-12 table-responsive">
          <table class="table table-hover table-bordered">
            <thead>
              <th>العنوان</th>
              <th>رقم الواتسآب</th>
              <th>خيارات</th>
            </thead>
            <tbody>
              <tr v-for="section in sections" :key="section._id">
                <td>
                  <input
                    type="text"
                    class="form-control"
                    v-model="section.title"
                  />
                </td>
                <td>
                  <select
                    class="form-control"
                    v-model="section.whatsapp_device_id"
                  >
                    <option></option>
                  </select>
                </td>
                <td>
                  <button class="btn btn-primary btn-sm" @click="save(section)">
                    <i class="fa fa-save"></i>
                    حفظ
                  </button>
                  &nbsp;
                  <button
                    class="btn text-danger btn-sm"
                    @click="deleteSec(section)"
                  >
                    <i class="fa fa-trash"></i>
                    حذف
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      students: [],
      user: JSON.parse(localStorage.getItem("user")),
      sections: [],
      addSection: {},
    };
  },
  created() {
    var g = this;
    if (!checkPer("settings")) {
      this.$router.push("/per");
      return false;
    }
    $.post(api + "/user/sections/list", {
      jwt: g.user.jwt,
    })
      .then(function (r) {
        g.sections = r.response;
      })
      .catch(function (e) {
        console.log(e);
        alert("حدث خطأ..");
      });
  },
  methods: {
    checkPer(e) {
      return checkPer(e);
    },
    add() {
      var g = this;
      g.$refs.addbtn.innerHTML = `<div class="spinner-border text-white" role="status"></div>`;
      $.post(api + "/user/students/import", {
        jwt: g.user.jwt,
        arr: JSON.stringify(g.students),
        section_id: g.section_id,
      })
        .then(function () {
          alert("تم استيراد الطلاب بنجاح");
          location.reload();
        })
        .catch(function (e) {
          console.log(e);
          alert("حدث خطأ..");
        });
    },
    save(sec) {
      var g = this;
      $.post(api + "/user/sections/edit", {
        jwt: g.user.jwt,
        id: sec._id,
        title: sec.title,
        whatsapp_device_id: sec.whatsapp_device_id,
      })
        .then(function (r) {
          alert("تم الحفظ بنجاح");
        })
        .catch(function (e) {
          console.log(e);
          alert("حدث خطأ..");
        });
    },
    deleteSec(sec) {
      if (
        confirm("متأكد من الحذف؟ ربما يؤثر على باقي السجلات التي في نفس القسم.")
      ) {
        var g = this;
        $.post(api + "/user/sections/delete", {
          jwt: g.user.jwt,
          id: sec._id,
        })
          .then(function (r) {
            $.post(api + "/user/sections/list", {
              jwt: g.user.jwt,
            })
              .then(function (r) {
                g.sections = r.response;
              })
              .catch(function (e) {
                console.log(e);
                alert("حدث خطأ..");
              });
          })
          .catch(function (e) {
            console.log(e);
            alert("حدث خطأ..");
          });
      }
    },
    addNew() {
      var g = this;
      var t = prompt("عنوان القسم:", "");
      if (t != null && t != "") {
        if (t.trim() != "") {
          $.post(api + "/user/sections/add", {
            jwt: g.user.jwt,
            title: t,
          })
            .then(function (r) {
              alert("تم الاضافة بنجاح");
              $.post(api + "/user/sections/list", {
                jwt: g.user.jwt,
              })
                .then(function (r) {
                  g.sections = r.response;
                })
                .catch(function (e) {
                  console.log(e);
                  alert("حدث خطأ..");
                });
            })
            .catch(function (e) {
              console.log(e);
              alert("حدث خطأ..");
            });
        }
      }
    },
  },
};
</script>